import { JobStyle } from "@headbot/library";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

export interface ISampleGalleryImage {
    readonly id: string;
    readonly src: IGatsbyImageData;
    readonly alt: string;
    readonly caption: string;
    readonly style: JobStyle;
}
interface ISampleGalleryProps {
    readonly photos: ReadonlyArray<ISampleGalleryImage>;
}

export const SampleGalleryFromQuery: React.FC<ISampleGalleryProps> = ({ photos }) => {
    const images = photos.map((img, index) => {
        const { src, alt, caption } = img;
        return (
            <div key={index} className="grid gap-4">
                <div>
                    <GatsbyImage image={src} alt={alt} className="h-auto max-w-full rounded-lg object-cover object-center" />
                    <h3 className="text-sm text-gray-700 mt-4 mb-4 text-center">{caption}</h3>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8 mb-4">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">See it in action</h2>
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8 ">
                <div className="grid grid-cols-2 gap-4 md:grid-cols-4">{images}</div>
            </div>
        </>
    );
};
