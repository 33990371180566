import { CheckIcon } from "@heroicons/react/24/solid";
import { Link } from "gatsby";
import * as React from "react";

interface IProps {
    readonly title: string;
    readonly ctaLabel: string;
    readonly ctaLink: string;
    readonly features: Array<{ readonly name: string }>;
    readonly featureImage: React.ReactNode;
}

export const HeroSection: React.FC<IProps> = ({ features, title, ctaLabel, featureImage, ctaLink }) => {
    return (
        <div className="relative isolate pt-4">
            <svg
                aria-hidden="true"
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            >
                <defs>
                    <pattern
                        x="50%"
                        y={-1}
                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                        width={200}
                        height={200}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                    <path
                        d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                        strokeWidth={0}
                    />
                </svg>
                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
            </svg>
            <div className="mx-auto max-w-7xl px-6 pb-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                    <h1 className="mt-4 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h1>
                    <dl className="mt-8 col-span-2 grid grid-cols-1 gap-x-8 gap-y-4 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <CheckIcon aria-hidden="true" className="absolute left-0 top-1 h-5 w-5 text-indigo-500" />
                                    {feature.name}
                                </dt>
                            </div>
                        ))}
                    </dl>
                    <div className="mt-10 mb-10 flex items-center gap-x-6">
                        <Link
                            to={ctaLink}
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {ctaLabel}
                        </Link>
                        {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                            Learn more <span aria-hidden="true">→</span>
                        </a> */}
                    </div>
                </div>
                <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">{featureImage}</div>
            </div>
        </div>
    );
};
